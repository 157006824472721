import * as React from "react"
import ReactMarkdown from "react-markdown"
import Layout from "../components/layout"
import Seo from "../components/seo"

const Blog = ({ pageContext: { post } }) => {
  return (
    <>
      <Layout
        headerComponent={
          <header className="header pt-8 pb-4 text-left text-white bg-dark text-center">
            <div className="container">
              <div className="row">
                <div className="col-md-12 mx-auto">
                  <h2>{post.Title}</h2>
                  <p>
                    {new Date(post.published_at).toLocaleDateString()} in{" "}
                    {post.Categories.map(({ Name, Slug }, index) => (
                      <a
                        key={index}
                        href={`/blog/${Slug}`}
                        style={{ marginRight: "5px" }}
                      >
                        {Name}
                      </a>
                    ))}
                  </p>
                </div>
              </div>
            </div>
          </header>
        }
      >
        <div className="section py-7 pt-0">
          <div className="container">
            {/* <div className="text-center mt-8">
            <h2>{post.Title}</h2>
            <p>
              {new Date(post.published_at).toLocaleDateString()} in{" "}
              {post.Categories.map(({ Name, Slug }, index) => (
                <a
                  key={index}
                  href={`/blog/${Slug}`}
                  style={{ marginRight: "5px" }}
                >
                  {Name}
                </a>
              ))}
            </p>
          </div> */}
            {post.Content.map((component, index) => {
              if (component.Image) {
                return (
                  <div className="text-center mb-6 mt-0" key={index}>
                    <img
                      className="rounded-md"
                      src={component.Image.childImageSharp.fluid.src}
                      alt={post.Title}
                    />
                  </div>
                )
              }

              if (component.Embed && component.Embed !== "`") {
                return (
                  <div className="row" key={index}>
                    <div className="col-lg-10 mx-auto text-center mb-6">
                      <div
                        className="responsive-iframe-container"
                        dangerouslySetInnerHTML={{ __html: component.Embed }}
                      />
                    </div>
                  </div>
                )
              }

              if (component.LeadParagraph && component.LeadParagraph !== "`") {
                return (
                  <div className="row" key={index}>
                    <div className="col-lg-10 mx-auto">
                      <p className="lead-3">{component.LeadParagraph}</p>
                      <hr className="w-100px" />
                    </div>
                  </div>
                )
              }

              if (
                component.NormalParagraph &&
                component.NormalParagraph !== "`"
              ) {
                return (
                  <div className="row" key={index}>
                    <div className="col-lg-10 mx-auto">
                      <p className="lead-2">
                        <ReactMarkdown>
                          {component.NormalParagraph}
                        </ReactMarkdown>
                      </p>
                    </div>
                  </div>
                )
              }

              if (
                component.BlockquoteText &&
                component.BlockquoteCite &&
                component.BlockquoteText !== "`" &&
                component.BlockquoteCite !== "`"
              ) {
                return (
                  <div className="row" key={index}>
                    <div className="col-lg-10 mx-auto">
                      <blockquote className="blockquote lead-3">
                        <div className="quote-sign" />
                        <p>{component.BlockquoteText}</p>
                        <footer>{component.BlockquoteCite}</footer>
                      </blockquote>
                    </div>
                  </div>
                )
              }
            })}
            <div className="row">
              <div className="col-lg-10 mx-auto">
                <div className="gap-xy-2 mt-6">
                  {post.Categories.map(({ Name, Slug }, index) => (
                    <a
                      key={index}
                      className="badge badge-pill badge-secondary"
                      href={`/blog/${Slug}`}
                    >
                      {Name}
                    </a>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>
      </Layout>
      <Seo title={post.Title} description={post.Description} />
    </>
  )
}

export default Blog
